var currentVersion = 'doordeck'

export default {
    // global context
    doordeck: {
        GREEN_ACCENT: '#73E6CE',
        MAP_ACCENT: '#45bdd1',
        SITE_COLORS: [
            '#57355D', '#854A90', '#C74BD1', '#DB2796', '#D93951', '#E85479', '#FF7979', '#FF5800',
            '#FF9442', '#FCC634', '#43E5C0', '#24BD9A', '#7AD651', '#4FB961', '#1F5468', '#1E81A7',
            '#314772', '#55678C', '#3E6BC6', '#38A3E0', '#5FC5FF', '#6641FF', '#4B3996'
        ]
    },
    getColor(string) {
        return this[currentVersion][string]
    },
    getCurrentVersion() {
        return currentVersion
    },
    setCurrentVersion(version) {
        currentVersion = version
    }
}
