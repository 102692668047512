import QRCode from 'qrcode-svg'

export default {
    getQRCode(customURL, id) {
        var qrCode = new QRCode({
            content: customURL + '/' + id,
            padding: 4,
            width: 300,
            height: 300,
            color: "#000000",
            background: "#ffffff",
            ecl: "H",
            container: "svg",
            join: true
        });
        var svg = qrCode.svg();
        return svg;
    },

    // Function to download data to a file
    download(data, filename, type) {
        var file = new Blob([data], {type: type});
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(file, filename);
        } else { // Others
            const a = document.createElement("a")
            const url = URL.createObjectURL(file);
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            setTimeout(function () {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 0);
        }
    }
}
