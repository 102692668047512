import Vue from 'vue'
import Vuex from 'vuex'
import * as actions from './actions'
import * as getters from './getters'
import auth from './modules/auth'
import data from './modules/data'
import route from './modules/routing'
import site from './modules/site'
import helper from './modules/helper'

Vue.use(Vuex)

const store = new Vuex.Store({
    getters,
    actions,
    modules: {
        auth,
        data,
        route,
        site,
        helper
    }
})
export default store
