import * as types from '../mutation-types'
import siteService from '../../services/site'

const state = {
    addSiteDialog: false,
    sites: JSON.parse(localStorage.getItem('sites')) || [],
    admins: [],
    locksFromSite: [],
    loadingSites: false,
    tempSites: []
}
// getters
const getters = {
    getSites: state => {
        if (state.sites !== null) return state.sites
        else return []
    },
    getAddSiteDialog: state => {
        return state.addSiteDialog
    },
    getSite(id) {
        return id => state.sites.filter(item => {
            return item.id === id
        })
    },
    getSiteAdmins: state => {
        return state.admins
    },
    getLockFromSite: state => {
        return state.locksFromSite
    },
    getLoadingSites: state => {
        return state.loadingSites
    }
}
// actions
const actions = {
    openAddSiteDialog({commit}, value) {
        state.addSiteDialog = value
    },
    refreshSites({commit, state, dispatch}) {
        state.loadingSites = true
        state.sites = JSON.parse(localStorage.getItem('sites'))
        siteService.getSites().then(response => {
            state.tempSites = response.data
            state.sites = response.data
            localStorage.setItem('sites', JSON.stringify(state.tempSites))
            state.loadingSites = false
        })
    },
    refreshSiteAdmins({commit}, siteId) {
        return siteService.getSiteAdmins(siteId).then(response => {
            state.admins = response.data
        })
    },
    addSite({commit}, site) {
        return siteService.addSite(site)
    },
    getUser({commit}, email) {
        return siteService.getUser(email)
    },
    deleteDevice({commit}, deviceId) {
        return siteService.deleteDevice(deviceId)
    },
    updateSite({commit}, {id, site}) {
        return siteService.updateSite(id, site)
    },
    removeSite({commit}, id) {
        return siteService.removeSite(id)
    },
    refreshLocksFromSite({commit}, siteId) {
        return siteService.getLockFromSite(siteId).then(response => {
            state.locksFromSite = response.data
        })
    },
    getAllLocksFromSites({dispatch, state}, index) {
        state.loadingSites = true
        return siteService.getLockFromSite(state.tempSites[index].id).then(response => {
            state.tempSites[index].locks = response.data
            state.tempSites[index].numOfLocks = response.data.length
            if (index < state.tempSites.length - 1) {
                dispatch('getAllLocksFromSites', index + 1)
            } else {
                state.loadingSites = false
                localStorage.setItem('sites', JSON.stringify(state.tempSites))
            }
        }, fail => {
            state.sites[index].locks = []
            state.sites[index].numOfLocks = 0
            if (index < state.tempSites.length - 1) {
                dispatch('getAllLocksFromSites', index + 1)
            } else {
                state.loadingSites = false
                localStorage.setItem('sites', JSON.stringify(state.tempSites))
            }
        })
    }
}
// mutations
const mutations = {
    [types.LOGIN_REQUEST](state) {
        state.pending = true
        state.error = false
    }
}
// export
export default {
    state,
    getters,
    actions,
    mutations
}
