import store from '../store'

export default {
    uuidStyle: "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx",

    getLockName(id) {
        var devices = store.getters.devices
        for (let i = 0, l = devices.length; i < l; i++) {
            if (id === devices[i].device_id) {
                return devices[i].default_name
            }
        }
    },
    isEmpty(obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }
        return true
    }
}
