<template>
  <div>
    <h6>Remove users</h6>
    <input v-model="email" class="line-input" placeholder="Email" type="email">
    <v-progress-circular v-if="loading"
                         style="margin-left: 20px"
                         indeterminate
                         color="primary">
    </v-progress-circular>
    <v-btn v-else color="cyan" @click="checkIfUserExist()">Remove</v-btn>

    <v-dialog v-model="removeDialog" class="d-dialog" max-width="500" width="400">
      <v-card>
        <v-card-title class="headline">Are you sure you want to remove {{ email }}?</v-card-title>
        <v-card-text>User added: {{ registerDate }}</v-card-text>
        <v-card-text>User Id: {{ userId }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" flat @click.native="closeRemoveDialog()">Cancel</v-btn>
          <v-btn color="cyan" @click.native="removeUser()">Remove</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="messageDialog" class="d-dialog" max-width="500" width="400">
      <v-card>
        <v-card-title class="headline">{{ title }}</v-card-title>
        <v-card-text>{{ subTitle }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="cyan" @click.native="closeMessageDialog()">Done</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import dataService from '../services/dataServices'

export default {
  name: 'remove_users',
  data() {
    return {
      loading: false,
      email: '',
      userId: '',
      removeDialog: false,
      messageDialog: false,
      registerDate: '',
      title: '',
      subTitle: ''
    }
  },
  methods: {
    async checkIfUserExist() {
      this.loading = true
      try {
        const response = await this.$store.dispatch('getUser', this.email)
        this.userId = response.data.id
        this.registerDate = this.getDateFromUuid(this.userId)
        this.openRemoveDialog()
      } catch (error) {
        this.openMessageDialog('Invalid user', "The following user doesn't exist: " + this.email)
      }
      this.loading = false
    },
    openMessageDialog(title, subtitle) {
      this.title = title
      this.subTitle = subtitle
      this.messageDialog = true
    },
    closeMessageDialog() {
      this.messageDialog = false
    },
    openRemoveDialog() {
      this.removeDialog = true
    },
    closeRemoveDialog() {
      this.removeDialog = false
      this.registerDate = ''
    },
    async removeUser() {
      this.closeRemoveDialog()
      this.loading = true
      try {
        await dataService.removeUser(this.userId)

        this.openMessageDialog('Success', this.email + " is removed.")
      } catch (error) {
        this.openMessageDialog('Error', "Couldn't remove user: " + this.email)
      }
      this.loading = false
    },
    getDateFromUuid(uuidStr) {
      const intTime = this.getTimeInMill(uuidStr) - 122192928000000000;
      const intMilliSec = Math.floor(intTime / 10000);
      return new Date(intMilliSec)
    },
    getTimeInMill(uuidStr) {
      const uuidArr = uuidStr.split('-');
      const timeStr = [
        uuidArr[2].substring(1),
        uuidArr[1],
        uuidArr[0]
      ].join('');
      return parseInt(timeStr, 16)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../main.scss';

.line-input {
  min-width: 240px;
}

.d-dialog {
  width: 500px;
}

h6 {
  float: initial;
}
</style>
  