// CommitChart.js
import {Bar} from 'vue-chartjs'
import moment from 'moment'

// var dates = {
//   now: moment().format('X'),
//   day: moment().subtract(1, 'days').format('MM-DD-YYYY'),
//   week: moment().subtract(7, 'days').format('MM-DD-YYYY'),
//   month: moment().subtract(30, 'days').format('MM-DD-YYYY')
// }

export default {
    extends: Bar,
    props: ['label', 'items', 'x', 'y'],
    data() {
        return {
            data: [],
            labels: [],
            min: null,
            loaded: false
        }
    },
    mounted() {
        // Overwriting base render method with actual data.
        this.setData()
    },
    watch: {
        items: function (val) {
            if (!this.loaded) {
                this.setData()
            }
            if (this.loaded) {
                this.setupChart()
            }
        }
    },
    methods: {
        setupChart() {
            this.renderChart({
                    labels: this.labels,
                    datasets: [
                        {
                            label: this.label,
                            backgroundColor: '#45bdd1',
                            data: this.data,
                            barPercentage: 1,
                            categoryPercentage: 0.5
                        }
                    ]
                },
                {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [{
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                min: this.min
                            }
                            // type: 'time',
                            // time: {
                            //   unit: 'day',
                            //   displayFormats: {
                            //     day: 'dd'
                            //   }
                            // }
                        }],
                        yAxes: [{
                            stacked: false,
                            gridLines: {
                                display: true,
                                zeroLineColor: 'transparant'
                            },
                            ticks: {
                                source: 'auto'
                            }
                        }]
                    }
                })
        },
        setData() {
            if (this.items !== undefined && this.items !== null) {
                this.data = []
                this.items.sort(this.compare)
                this.items.map(item => {
                    this.labels.push(this.dateFromDay(item[this.x]))
                    this.data.push(item[this.y])
                })
                this.min = this.getMin()
                this.loaded = true
            }
        },
        compare(a, b) {
            if (a[this.x] < b[this.x]) {
                return -1
            }
            if (a[this.x] > b[this.x]) {
                return 1
            }
            return 0
        },
        getMin() {
            var index = this.labels.length - 30
            if (index > 0) {
                return this.labels[index]
            } else {
                return null
            }
        },
        dateFromDay(days) {
            var date = new Date(new Date().getFullYear(), 0) // initialize a date in `year-01-01`
            return moment(date).add(days - 1, 'days').format('ddd DD')// add the number of days
        }
    }
}

