<template>
  <div class="hello">
    <v-container fluid grid-list-md>
      <v-layout column wrap>
        <v-tabs
            v-model="active"
            slider-color="cyan"
        >
          <v-tab :key="'info'">
            Info
          </v-tab>
          <v-tab :key="'locks'">
            Locks
          </v-tab>
          <v-tab-item :key="'info'">
            <v-card text>
              <v-card-text>
                <div class="card-section">
                  <v-container container--fluid>
                    <v-layout column wrap>
                      <h6>Site info</h6>
                      <input v-model="site.name" class="line-input" placeholder="Site name" type="text"
                             @change="updateName()">
                    </v-layout>
                  </v-container>
                  <v-container container--fluid>
                    <v-layout column wrap>
                      <input v-model="site.creditRemaining" class="line-input" placeholder="Door credits remaining"
                             type="text" @change="updateCredit()">
                    </v-layout>
                  </v-container>
                  <v-container container--fluid>
                    <v-select
                        v-model="site.category"
                        :items="categories"
                        item-text="name"
                        item-value="name"
                        label="Select Category"
                        single-line
                        v-on:change="updateCategory"
                    >
                    </v-select>
                  </v-container>

                  <v-container container--fluid>
                    <v-layout column wrap>
                      <h6>Site admin(s) </h6>
                      <v-combobox
                          v-model="adminSelect"
                          :items="Array.from(adminMap).map((e) => { return e[0]; })"
                          :label="strings.getString('SHARE_EMAILS')"
                          chips
                          clearable
                          multiple
                          solo
                          tags
                          v-on:blur="updateAdmins()"
                          v-on:keyup.enter="updateAdmins()"
                          v-on:keyup.tab="updateAdmins()"
                      >
                        <template slot="selection" slot-scope="data">
                          <v-chip :input_value="data.selected" close>
                            <strong>{{ data.item }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-layout>
                  </v-container>

                  <v-container container--fluid>
                    <v-layout column wrap>
                      <h6> Location </h6>
                      <v-list>
                        <v-list-item @click="openEditLocation()">
                          <v-list-item-action>
                            <div class="dialog-action-button">
                              <v-icon>fa-map-marker</v-icon>
                            </div>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Latitude: {{ site.latitude }} | Longitude: {{ site.longitude }}
                            </v-list-item-title>
                            <v-list-item-subtitle>Radius: {{ site.radius }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-layout>
                  </v-container>

                  <v-container container--fluid>
                    <v-layout column wrap>
                      <h6> Customization </h6>
                      <v-layout row wrap>
                        <v-flex sm1 xs1>
                          <div class="color-pick" v-bind:style="{ 'background': site.colour }"></div>
                        </v-flex>
                        <v-flex sm5 xs11>
                          <v-select
                              v-model="site.colour"
                              :items="colors"
                              item-text="name"
                              item-value="name"
                              label="Select Color"
                              single-line
                              @change="updateColor"
                          >
                            <template slot="item" slot-scope="data">
                              <template>
                                <div class="color-pick" v-bind:style="{ 'background': data.item }"></div>
                                <v-list-item-content>
                                  <v-list-item-title v-html="data.item"></v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-select>
                        </v-flex>
                        <v-flex sm5 xs11>

                        </v-flex>
                      </v-layout>
                    </v-layout>
                  </v-container>
                </div>

                <v-container v-if="loading" container--fluid>
                  <v-layout justify-center align-center>
                    <v-spacer></v-spacer>
                    Updating...
                    <v-progress-circular
                        style="margin-left: 20px"
                        indeterminate
                        color="primary">
                    </v-progress-circular>
                  </v-layout>
                </v-container>

                <v-container v-else container--fluid>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" text @click.native="showDialog()">Remove</v-btn>
                    <v-btn color="cyan" @click.native="updateSite()">Update</v-btn>
                  </v-card-actions>
                </v-container>

              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item :key="'locks'">
            <v-card>
              <v-card-text>
                <v-container container--fluid>
                  <v-layout column wrap>
                    <h6>{{ site.name }} Locks</h6>
                  </v-layout>

                  <v-data-table
                      :headers="list.locks.headers"
                      :items="locks"
                      :items-per-page="10">

                    <template v-slot:top>
                      <v-dialog v-model="deleteLockDialog" max-width="500" width="400">
                        <v-card>
                          <v-card-title>Are you sure you want to delete <span
                              class="font-weight-bold"> {{ " " + deleteLockSelected.name }}</span>?
                          </v-card-title>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="deleteLockCancel">Cancel</v-btn>
                            <v-btn color="blue darken-1" text @click="deleteLockConfirm">OK</v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-icon small @click="deleteLock(item)">
                        fa-trash
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-container>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-layout>

    </v-container>
    <v-dialog v-model="locationDialog" persistent>
      <v-card>
        <v-card-title class="headline">Edit location and radius</v-card-title>
        <v-card-text>
          <LocationDialog :reloadMap="locationDialog"></LocationDialog>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="cyan" @click.native="dismissLocationDialog">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="removeDialog" class="d-dialog" max-width="500" width="400">
      <v-card>
        <v-card-title class="headline">Are you sure you want to remove this site?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click.native="dismissDialog()">Cancel</v-btn>
          <v-btn color="cyan" @click.native="removeSite()">Remove</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LocationDialog from '../components/LocationDialog'
import globalColors from '../services/colors'
import helper from '../services/helpers'
import arrays from "@/services/arrays";
import strings from "@/services/strings";

export default {
  name: 'site-detail',
  data() {
    return {
      loading: false,
      siteName: '',
      showMap: false,
      locationDialog: false,
      messageDialog: false,
      deleteLockDialog: false,
      deleteLockSelected: {},
      siteCredits: '',
      colors: globalColors.getColor('SITE_COLORS'),
      color: '#57355D',
      adminMap: new Map(),
      adminSelect: [],
      initAdmins: [],
      title: '',
      subTitle: '',
      removeDialog: false,
      site: {},
      changedSite: {},
      resetSite: {},
      active: '',
      categories: arrays.SiteCategories,
      list: {
        locks: {
          select: {
            default: 'connected'
          },
          headers: [
            {text: 'Name', sortable: true, align: 'start', value: 'name'},
            {text: 'Door Id', sortable: true, align: 'start', value: 'id'},
            {text: 'Actions', sortable: false, align: 'end', value: 'actions'}
          ],
          pagination: {
            descending: true,
            rows: 20
          }
        }
      }
    }
  },
  methods: {
    async removeSite() {
      this.dismissDialog()
      this.$store.dispatch('removeSite', this.$route.params.id).then(response => {
        this.refreshAndGoBack()
      })
    },
    goToLock(id) {
      this.$router.push({name: 'device-stat', params: {id: id}})
    },
    showDialog() {
      this.removeDialog = true
    },
    dismissDialog() {
      this.removeDialog = false
    },
    openEditLocation() {
      let coordSite = {
        latitude: this.site.latitude,
        longitude: this.site.longitude,
        radius: this.site.radius
      }
      this.$store.dispatch('updateSiteCoordToAdd', coordSite)
      this.locationDialog = true
    },
    dismissLocationDialog() {
      if (this.location.latitude !== this.site.latitude) {
        this.site.latitude = this.location.latitude
        this.changedSite.latitude = this.site.latitude
      }
      if (this.location.longitude !== this.site.longitude) {
        this.site.longitude = this.location.longitude
        this.changedSite.longitude = this.site.longitude
      }
      if (this.location.radius !== this.site.radius) {
        this.site.radius = this.location.radius
        this.changedSite.radius = this.site.radius
      }
      this.locationDialog = false
    },
    refreshSiteAdmins() {
      this.$store.dispatch('refreshSiteAdmins', this.$router.currentRoute.params.id).then(response => {
        this.initAdmins = this.$store.getters.getSiteAdmins
        this.initAdmins.forEach((element) => {
          this.adminSelect.push(element.email)
          this.adminMap.set(element.email, element.userId)
        })
      })
    },
    async deleteLock(item) {
      this.deleteLockSelected = item
      this.deleteLockDialog = true
    },
    async deleteLockConfirm() {
      try {
        const response = await this.$store.dispatch('deleteDevice', this.deleteLockSelected.id)
        await this.$store.dispatch('refreshLocksFromSite', this.$router.currentRoute.params.id)
      } catch (error) {
        console.log(error)
        this.openMessageDialog('Network Error', "You don't have permission or request failed.")
      }

      this.deleteLockDialog = false
    },
    async deleteLockCancel(item) {
      this.deleteLockDialog = false
    },
    async updateAdmins() {
      if (!this.adminSelect.length > 0) return false

      let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      let lastItem = this.adminSelect.pop()
      let split = lastItem.split(/(?:,| |;)+/)
      let invalidUsers = []

      for (const element of split) {
        if (emailRegex.test(String(element).toLowerCase())) {
          try {
            let userId = "";

            if (this.adminMap.has(element)) {
              userId = this.adminMap.get(element)
            } else {
              const response = await this.$store.dispatch('getUser', element)
              userId = response.data.id
            }

            this.adminSelect.push(element)
            this.adminMap.set(element, userId)
          } catch (error) {
            invalidUsers.push(element)
          }
        }
      }

      if (invalidUsers.length > 0) {
        this.openMessageDialog('Invalid users', "The following users don't exist: " + invalidUsers.toString())
      }
    },
    updateName() {
      this.changedSite.name = this.site.name
    },
    updateCredit() {
      this.changedSite.creditRemaining = this.site.creditRemaining
    },
    updateCategory() {
      this.changedSite.category = this.site.category
    },
    updateColor(colour) {
      this.changedSite.colour = colour
    },
    updateSite() {
      let adminsToRemove = []
      let adminsToAdd = []

      const initEmails = this.initAdmins.map(obj => obj.email);

      // Find the new admins
      this.adminSelect.forEach((email) => {
        if (!initEmails.includes(email)) {
          adminsToAdd.push(this.adminMap.get(email))
        }
      })

      // Find the removed admins
      this.initAdmins.forEach((element) => {
        const email = element.email
        if (!this.adminSelect.includes(email)) {
          adminsToRemove.push(this.adminMap.get(email))
        }
      })

      if (adminsToAdd.length > 0) {
        this.changedSite.addAdministrators = adminsToAdd
      }
      if (adminsToRemove.length > 0) {
        this.changedSite.removeAdministrators = adminsToRemove
      }
      if (!helper.isEmpty(this.changedSite)) {
        this.loading = true

        this.$store.dispatch('updateSite', {
          id: this.$router.currentRoute.params.id,
          site: this.changedSite
        }).then(response => {
          this.refreshAndGoBack()
        })
      }
    },
    async refreshAndGoBack() {
      // Custom delay is needed to make sure the sites are updated on the backend
      await new Promise(r => setTimeout(r, 1500))
      await this.refreshSites()
      this.loading = false
      await this.$router.push({name: 'sites'})
    },
    async refreshSites() {
      await this.$store.dispatch('refreshSites')
    }
  },
  computed: {
    strings() {
      return strings
    },
    getAddress() {
      return this.$store.getters.getAddressSiteToAdd
    },
    addSiteDialog() {
      return this.$store.getters.getAddSiteDialog
    },
    location() {
      return this.$store.getters.getCoordSiteToAdd
    },
    locks() {
      return this.$store.getters.getLockFromSite
    }
  },
  mounted() {
    this.refreshSites()
    this.site = this.$store.getters.getSite(this.$router.currentRoute.params.id)[0]
    this.resetSite = this.site
    this.refreshSiteAdmins()
    this.$store.dispatch('refreshLocksFromSite', this.$router.currentRoute.params.id)
  },
  components: {
    LocationDialog
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../main.scss';

.color-pick {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 16px 16px;
}
</style>
<style lang="scss">
.v-tabs__bar {
  margin: 0 32px;
}
</style>