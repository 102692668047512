<template>
  <div class='side-bar'>
    <div class='container'>
      <img class='logo' src='../assets/doordeck-logo-light.svg'>
      <ul>
        <li v-for='item in items' :key='item.title'>
          <v-list-item v-bind:class="{ 'cyan--text': item.active }" @click="goTo(item.route)">
            <v-list-item-icon>
              <v-icon color="white" v-bind:class="{ 'cyan--text': item.active }">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="white--text" v-bind:class="{ 'cyan--text': item.active }"
                                 v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list class='subItems' v-bind:class="{ 'open': item.open }">
            <v-list-item v-for='subItem in item.subItems' :key='subItem.title' @click="goTo(subItem.route)">
              <v-list-item-content v-bind:class="{ 'cyan--text': subItem.active, }">
                <v-list-item-title class="white--text">{{ subItem.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </li>
      </ul>
      <ul class="bottom-buttons">
        <li>
          <v-list-item @click="logout()">
            <v-list-item-action>
              <v-icon color="white">fa-sign-out</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="white--text">{{ strings.getString("LOGOUT") }}</v-list-item-title>

            </v-list-item-content>
          </v-list-item>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import strings from "../services/strings";

export default {
  name: 'sidebar',
  computed: {
    strings() {
      return strings
    }
  },
  data: function () {
    return {
      items: [
        {title: 'Sites', icon: 'fa-building', active: false, open: false, route: 'sites', subItems: []},
        {title: 'Settings', icon: 'fa-cog', active: false, open: false, route: 'settings', subItems: []},
        {title: 'Tiles', icon: 'fa-qrcode', active: false, open: false, route: 'tiles', subItems: []}
      ]
    }
  },
  mounted: function () {
    this.setActive(this.$router.currentRoute.name)
  },
  watch: {
    '$route'(to, from) {
      this.setActive(to.name)
    }
  },
  methods: {
    setActive: function (route) {
      for (var i = 0, l = this.items.length; i < l; i++) {
        var item = this.items[i]
        if (item.route === route) {
          item.active = true
          if (item.subItems.length > 0) {
            item.open = true
          }
        } else {
          item.active = false
          item.open = false
        }
        for (var j = 0, k = item.subItems.length; j < k; j++) {
          if (item.subItems[j].route === route) {
            item.subItems[j].active = true
            item.open = true
          } else {
            item.subItems[j].active = false
          }
          for (var m = 0, n = item.subItems[j].subItems.length; m < n; m++) {
            if (item.subItems[j].subItems[m].route === route) {
              item.subItems[j].active = true
              item.open = true
            } else {
              // item.subItems[j].active = false
            }
          }
        }
      }
    },
    goTo: function (route) {
      // console.log(route)
      this.$router.push({name: route})
    },
    logout: function () {
      // console.log(route)
      this.$store.dispatch('logout')
    }
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang='scss' scoped>
@import '../main.scss';

.side-bar {
  float: left;
  width: $side-bar;
  display: block;
  height: 100vh;
  background: rgba($base-dark, 0.98);
  position: relative;

}

.logo {
  padding: 16px;
  width: 100%;
}

.container {
  padding: 32px 16px;
}

.bottom-buttons {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.theme--light {
  .v-list {
    background: none;
  }

  li {
    .v-list__tile__action {
      min-width: 36px;

      i {
        color: $font-white;
      }
    }

    .v-list__tile__title {
      color: $font-white;
      font-weight: 500;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0 -15px;
    transition: height 0.3s ease-out, font-size 0.3s ease-out;

    &.subItems {
      margin: 0;
      height: 0;
      overflow: hidden;


      &.open {
        height: 96px;

      }

      .list__tile--active {
        .list__tile__title {
          color: $green-accent;
          font-size: 13px;
        }
      }

      .list__tile__title {
        color: $font-white;
        font-weight: 400;
      }
    }

    li .list__tile--active {
      .list__tile__title {
        color: $green-accent;
        font-size: 17px;
      }

      .list__tile__action i {
        color: $green-accent;
      }
    }
  }

}
</style>

<style lang='scss'>
.theme--light {

  .list__tile {
    padding: 0 32px;
  }

  li .subItems {
    .list__tile {
      padding-left: 68px;
      margin: 0;
      height: 32px;
      font-size: 12px;
    }
  }
}


// .list--dense .list__tile:not(.list__tile--avatar) {
//   height: auto;
//   display: block;
// }
// .list__tile{
//      margin: 0 -15px;
//      color: $font-white;
// }
</style>
