import * as types from '../mutation-types'
import auth from '../../services/authServices'
import router from '../../router'
import jwtDecode from "jwt-decode";

// initial state
// shape: [{ id, quantity }]
const state = {
    isLoggedIn: !!localStorage.getItem('token'),
    email: localStorage.email,
    pending: false,
    error: false,
    errorMessage: ''
    // isLoggedIn: true
}
// getters
const getters = {
    isLoggedIn: state => {
        return state.isLoggedIn
    },
    getEmail: state => {
        return state.email
    },
    isLogginIn: state => {
        return state.pending
    },
    error: state => {
        return state.error
    },
    errorMessage: state => {
        return state.errorMessage
    }
}
// actions
const actions = {
    login({commit}, creds) {
        commit(types.LOGIN_REQUEST) // show spinner
        auth.login(creds).then((response) => {
            if (response.status === 200) {
                commit(types.LOGIN_SUCCESS)
            } else if (response.status === 500 || response.status === 0) {
                commit(types.LOGIN_FAILURE, 'No internet connection.')
            } else if (response.status === 401) {
                commit(types.LOGIN_FAILURE, 'Wrong credentials.')
            } else {
                commit(types.LOGIN_FAILURE, 'Something went wrong.')
            }
        })
    },
    dismissError({commit}) {
        commit(types.RESET_ERROR)
    },
    logout({commit}) {
        auth.logout()
        localStorage.clear()
        commit(types.LOGOUT)
    },
    checkToken({commit, _}) {
        if (state.isLoggedIn) {
            var exp = jwtDecode(localStorage.token).exp
            if ((Date.now() / 1000) > exp) {
                if (localStorage.refreshToken !== undefined) {
                    auth.refreshToken().then(function () {
                            return true
                        },
                        function () {
                            commit(types.LOGOUT)
                            return false
                        })
                } else {
                    commit(types.LOGOUT)
                    return false
                }
            } else return true
        } else return false
    },
    checkEmail() {
        if (state.isLoggedIn) {
            if (state.email === undefined || state.email === null) {
                localStorage.email = jwtDecode(localStorage.token).email
                state.email = localStorage.email
            }
        }
    }
}
// mutations
const mutations = {
    [types.LOGIN_REQUEST](state) {
        state.pending = true
        state.error = false
    },
    [types.RESET_ERROR](state) {
        state.error = false
        state.errorMessage = ''
    },
    [types.LOGIN_SUCCESS](state) {
        state.isLoggedIn = true
        state.pending = false
        state.email = localStorage.email
        router.push({name: 'dashboard'})
    },
    [types.LOGIN_FAILURE](state, message) {
        state.pending = false
        state.error = true
        state.errorMessage = message
    },
    [types.LOGOUT](state) {
        state.isLoggedIn = false
        state.pending = false
        state.email = ''
        router.push({name: 'login'})
    },
    [types.VALID_TOKEN](_) {
        return true
    },
    [types.TOKEN_REFRESHED](_) {
        console.log('token succesfully refreshed')
    }
}
// export
export default {
    state,
    getters,
    actions,
    mutations
}
