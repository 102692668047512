<template>
  <div class="dashboard">
    <h1>This is locks route</h1>
  </div>
</template>

<script>
export default {
  name: 'login',
  data() {
    return {
      msg: 'Welcome to Your Vue.js App'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../main.scss';
</style>
 