<template>
  <div>
    <v-container container--fluid grid-list-md>
      <v-layout row wrap>
        <v-flex chart d-flex sm12>
          <tile-updates></tile-updates>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import removeUsers from '../components/RemoveUsers'
import tiles from '../components/Tiles'
import tileUpdates from '../components/TileLinks/index.vue'

export default {
  name: 'settings',
  data() {
    return {}
  },
  methods: {},
  components: {
    'remove-users': removeUsers,
    tiles,
    tileUpdates
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../main.scss';

.flex {
  display: block;
}
</style>
  