<template>
  <div class="d-list">
    <div class="d-list-top">
      <h6>{{ label }}</h6>
      <div v-if="filterItems[0]?.value !== undefined" class="time-select">
        <v-select
            v-model="selected"
            autofocus
            class="day-select"
            hide-details
            item-text="name"
            item-value="value"
            label="Items"
            single-line
            v-bind:items="filterItems"
        ></v-select>
      </div>
    </div>
    <v-data-table
        v-if="loaded"
        :headers="headers"
        :items="items"
        :items-per-page="5"
        class="elevation-1"
    >
      <template v-slot:item.name="{ item }">
        <a @click="goToLink(item.device_id, 'device-stat')">
          {{ item.name }}
        </a>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: ['label', 'items', 'initheaders', 'initpagination', 'select'],
  name: 'graph',
  data() {
    return {
      pagination: {},
      selected: this.select.default,
      filterItems: [
        {name: 'day', value: this.select.day},
        {name: 'week', value: this.select.week},
        {name: 'month', value: this.select.month}
      ],
      loaded: false
    }
  },
  mounted() {
    this.checkData()
  },
  created() {
    this.pagination.sortBy = this.selected
    this.pagination.descending = this.initpagination.descending
  },
  methods: {
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },
    goToLink(item, link) {
      if (link !== undefined) {
        // named route
        this.$router.push({name: link, params: {id: item}})
      }
    },
    checkData() {
      if (this.items !== undefined && this.items !== null) {
        if (this.items.length > 0) {
          this.loaded = true
        }
      }
    },
    getValue(item, header) {
      if (header.type === 'date') {
        return moment.unix(item[header?.value]).format('DD / MM / YYYY')
      } else return item[header?.value]
    }
  },
  computed: {
    headers: function () {
      if (this.selected !== undefined) {
        return this.initheaders.filter((h, i) => h?.value === this.selected || i === 0)
      } else {
        return this.initheaders
      }
    }
  },
  watch: {
    selected: function (value) {
      this.pagination.sortBy = value
      return value
    },
    items: function (val) {
      this.checkData()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../main.scss';

h6 {
  margin-top: 3px;
}

.link {
  cursor: pointer;
  color: $font-accent;

  &:hover {
    text-decoration: underline;
  }
}

.no-link {
  color: $font-black;
}

.v-select {
  width: 100px;
  margin-top: 4px;
}

</style>

<style lang="scss">

.d-list {
  display: flex;
  flex-direction: column;

  .table thead:last-of-type {
    border-bottom: none;
  }

  .table tbody tr {
    border-bottom: 1px solid #F0EFF0;

    &:hover {
      background: none;
    }
  }

  table.table tbody td:first-child, table.table tbody td:not(:first-child), table.table tbody th:first-child, table.table tbody th:not(:first-child), table.table thead td:first-child, table.table thead td:not(:first-child), table.table thead th:first-child, table.table thead th:not(:first-child) {
    padding: 0;
  }

  table.table thead tr {
    height: 32px;

    th {
      font-size: 11px;
    }
  }
}

.d-list-top {
  display: flex;
  flex-direction: row;
}

.day-select {
  padding-top: 0px !important;
  padding-left: 10px !important;
  background: white !important;
}

</style>