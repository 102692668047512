require('./assets/fonts/font-awesome/css/font-awesome.min.css')

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import vueResource from 'vue-resource'

import sidebar from '@/components/SideBar'
import barChart from '@/components/deckBarChart'
import lineChart from '@/components/LineChart'
import list from '@/components/List'
import * as VueGoogleMaps from 'vue2-google-maps'

import JsonExcel from 'vue-json-excel'


Vue.config.productionTip = false

Vue.use(vuetify)
Vue.use(vueResource)

Vue.component('d-sidebar', sidebar)
Vue.component('d-bar-chart', barChart)
Vue.component('d-line-chart', lineChart)
Vue.component('d-list', list)
Vue.component('downloadExcel', JsonExcel)

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCpbjo7fvhjip7do2XBl7gPkFV40lPbcPM',
        libraries: 'places' // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
    }
})
new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
