<template>
  <div class="splash main-view">
    <div class="container">
      <img class="logo" src="../assets/doordeck-logo-light.svg">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'splash',
  data() {
    return {
      msg: 'Welcome to Your Vue.js App'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../main.scss';

.splash {
  background: rgba($base-dark, 0.98);
  height: 100vh;
  display: block;

  .container {
    width: $splash-container-w;
    padding-top: 20vh;
  }

}

.logo {

  margin: 0 20px 15vh 20px;
  display: block;
}
</style>
  