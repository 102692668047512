// CommitChart.js
import {Line} from 'vue-chartjs'
import moment from 'moment'

var dates = {
    now: moment().format('X'),
    day: moment().subtract(1, 'days').format('X'),
    week: moment().subtract(7, 'days').format('X'),
    month: moment().subtract(30, 'days').format('X')
}

export default {
    extends: Line,
    props: ['label', 'items', 'x', 'y', 'z'],
    data() {
        return {
            index: 0,
            lastDay: null,
            data: []
            // init: this.getData
        }
    },
    mounted() {
        // Overwriting base render method with actual data.
        // if (this.items !== undefined && this.items.length > 0) {
        //   this.items.map(item => {
        //     var newItem = {}
        //     newItem.x = moment(item[this.x]).format()
        //     newItem.y = this.getValue(item[this.x])
        //     newItem.z = item.issuer.email
        //     console.log(newItem)
        //     return newItem
        //   })
        //   this.setupChart()
        //   return true
        // } else {
        //   return false
        // }
    },
    methods: {
        getValue(timestamp) {
            var now = new Date(timestamp)
            var start = new Date(now.getFullYear(), 0, 0)
            var diff = now - start
            var oneDay = 1000 * 60 * 60 * 24
            var day = Math.floor(diff / oneDay)
            if (this.lastDay !== null) {
                if (this.lastDay === day) {
                    this.index++
                } else {
                    this.index = 0
                }
            } else {
                this.index = 0
            }
            this.lastDay = day
            return this.index
        },
        setupChart() {
            this.renderChart({
                    datasets: [
                        {
                            label: this.label,
                            backgroundColor: '#73E6CE',
                            data: this.data
                        }
                    ]
                },
                {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [{
                            type: 'time',
                            time: {
                                // format: 'MM/DD/YYYY',
                                unit: 'day',
                                unitStepSize: 1,
                                displayFormats: {
                                    'day': 'MMM D'
                                },
                                min: dates.month * 1000,
                                max: dates.now * 1000
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                // max: 'auto',
                                // min: 0
                            }
                        }]
                    },
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItem, data) {
                                let label = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].z
                                let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].y
                                return label + ': ' + value
                            }
                        }
                    }
                })
        }
    },
    watch: {
        items: function (val) {
            if (this.items !== undefined && this.items.length > 0) {
                this.items.map(item => {
                    if (item.issuer !== undefined) {
                        var newItem = {}
                        newItem.x = moment(item[this.x]).format()
                        newItem.y = this.getValue(item[this.x])
                        newItem.z = item.issuer.email
                        // console.log(newItem)
                        this.data.push(newItem)
                        return newItem
                    }
                })
                this.setupChart()
                return true
            } else {
                return false
            }
        }
    },
    computed: {
        // getData () {
        //   if (this.items !== undefined) {
        //     this.items.map(item => {
        //       var newItem = {}
        //       newItem.x = moment(item[this.x]).format()
        //       newItem.y = this.getValue(item[this.x])
        //       newItem.z = item.issuer.email
        //       console.log(newItem)
        //       return newItem
        //     })
        //     this.setupChart()
        //     return true
        //   } else {
        //     return false
        //   }
        // }
    }
}
// function dateFromDay (day) {
//   var date = new Date(new Date().getFullYear(), 0) // initialize a date in `year-01-01`
//   return date.setDate(day) // add the number of days
// }

