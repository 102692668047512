<template>
  <div>
    <h5 class="title"> Select location </h5>
    <v-container container--fluid>
      <v-layout row wrap>
        <v-flex>

          <gmap-autocomplete :value="description"
                             class="autocomplete"
                             @place_changed="setPlace"
          >
          </gmap-autocomplete>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container>
      <div v-if="showMap">
        <gmap-map
            :center="mapCenter"
            :zoom="zoom"
            map-type-id="roadmap"
            style="width: 100%; height: 30vh; min-width: 300px"
            @center_changed="updateCenter">
          <gmap-marker
              :clickable="false"
              :draggable="false"
              :icon="{url:'https://image.ibb.co/k9v466/marker2.png'}"
              :position="markerpos">
          </gmap-marker>
          <gmap-circle
              :center="mapCenter"
              :options="{
                      strokeColor: colors.getColor('GREEN_ACCENT'),
                      strokeOpacity: 1,
                      strokeWeight: 1,
                      fillColor: colors.getColor('GREEN_ACCENT'),
                      fillOpacity: 0.4
                    }"
              :radius="getMapRadius"
          />
        </gmap-map>
      </div>
    </v-container>
    <h5 class="title"> Select Radius </h5>
    <v-container>
      <v-layout row wrap>
        <v-flex>
          <v-text-field
              v-model="mapRadius"
              label="Radius"
              suffix="meters"
              type="number"
              v-on:change="radiusChange"
          ></v-text-field>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import colors from '../services/colors'

export default {
  name: 'LocationDialog',
  props: ['reloadMap'],
  data() {
    return {
      mapCenter: {lat: 51.521986, lng: -0.084728},
      showMap: false,
      zoom: 17,
      mapRadius: 50,
      description: '',
      colors: colors,
      address_components: [],
      address: {
        premise: '',
        street_number: '',
        street: '',
        city: '',
        country: '',
        postal_code: ''
      }
    }
  },
  methods: {
    updateCenter(newCenter) {
      this.mapCenter = {
        lat: newCenter.lat(),
        lng: newCenter.lng()
      }
      var loc = {
        latitude: this.mapCenter.lat,
        longitude: this.mapCenter.lng,
        radius: this.mapRadius
      }
      this.$store.dispatch('updateSiteCoordToAdd', loc)
    },
    radiusChange() {
      var loc = {
        latitude: this.mapCenter.lat,
        longitude: this.mapCenter.lng,
        radius: this.mapRadius
      }
      this.$store.dispatch('updateSiteCoordToAdd', loc)
    },
    setPlace(place) {
      this.mapCenter = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      }
      this.address_components = place.address_components
      this.setAddress()
      this.zoom = 17
      var loc = {
        latitude: this.mapCenter.lat,
        longitude: this.mapCenter.lng,
        radius: this.mapRadius
      }
      this.$store.dispatch('updateSiteCoordToAdd', loc)
    },
    updateMap() {
      if (this.reloadMap === true) {
        this.showMap = true
      } else {
        setTimeout(function () {
          this.showMap = false
        }, 1000)
      }
    },
    setAddress() {
      for (let i = 0, l = this.address_components.length; i < l; i++) {
        var comp = this.address_components[i]
        switch (comp.types[0]) {
          case 'premise':
            this.address.premise = comp.long_name
            break
          case 'street_number':
            this.address.street_number = comp.long_name
            break
          case 'route':
            this.address.street = comp.long_name
            break
          case 'postal_town':
            this.address.city = comp.long_name
            break
          case 'country':
            this.address.country = comp.short_name
            break
          case 'postal_code':
            this.address.postal_code = comp.long_name
            break
        }
        this.$store.dispatch('updateAddressToAdd', this.address)
      }
      console.log(this.address)
    }
  },
  watch: {
    'reloadMap': 'updateMap'
  },
  mounted() {
    this.mapCenter.lat = this.$store.getters.getCoordSiteToAdd.latitude
    this.mapCenter.lng = this.$store.getters.getCoordSiteToAdd.longitude
  },
  computed: {
    getMapRadius() {
      return parseInt(this.mapRadius)
    },
    markerpos() {
      this.mapCenter.lat = this.$store.getters.getCoordSiteToAdd.latitude
      this.mapCenter.lng = this.$store.getters.getCoordSiteToAdd.longitude
      return {lat: this.mapCenter.lat - 0.00013, lng: this.mapCenter.lng}
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../main.scss';

.autocomplete {
  width: 100%;
  border-bottom: 1px solid rgba(black, 0.4);
  padding: 4px 0;
  font-size: 16px;
}

.container--fluid {
  padding: 16px;
}
</style>