import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'md'
    },
    theme: {
        themes: {
            light: {
                colors: {
                    primary: '#45bdd1',
                    secondary: '#051c2a',
                    light: '#FAFAFA',
                    medium: '#E8E9EB',
                    accent: '#45bdd1',
                    error: '#FF5252',
                    info: '#2196F3',
                    success: '#73E6CE',
                    warning: '#FFC107',
                    button: '#ffffff',
                    sidebar: '#E8E9EB',
                    sidebarIcon: '#969696',
                    siteName: '#051c2a',
                    appView: '#E8E9EB',
                    itemCard: '#ffffff',
                    detailView: '#ffffff',
                    dialogView: '#ffffff',
                    errorView: '#FAFAFA'
                }
            }
        }
    }
});
