<template>
  <div class="login">
    <v-form ref="form" v-model="valid" dark @submit.prevent="submit">
      <v-text-field v-model="email"
                    :rules="[rules.required, rules.email]"
                    class="splash-input"
                    dark
                    label="E-mail"
      ></v-text-field>
      <v-text-field
          v-model="password"
          :rules="[rules.required]"
          class="splash-input cyan--text"
          dark
          label="Password"
          type="password"
      ></v-text-field>
      <v-btn
          :disabled="!valid"
          :loading="loading"
          class="submit-btn cyan"
          type="submit"
      >Login
      </v-btn>
    </v-form>
    <v-dialog v-model="error" class="d-dialog" max-width="500" width="400">
      <v-card>
        <v-card-title class="headline">{{ errorMessage }}</v-card-title>
        <v-card-text>{{ subTitle }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="grey" text="flat" @click.native="closeMessageDialog()">Done</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'login',
  data() {
    return {
      valid: true,
      email: '',
      password: '',
      rules: {
        required: (value) => !!value || 'Required.',
        email: (value) => {
          const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        }
      },
      messageDialog: false,
      title: '',
      subTitle: 'Please try again.'
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        // Native form submission is not yet supported
        // axios.post('/api/submit', {
        //   name: this.name,
        //   email: this.email,
        //   select: this.select,
        //   checkbox: this.checkbox
        // })
        this.$store.dispatch('login', {
          email: this.email,
          password: this.password
        })
      }
    },
    clear() {
      this.$refs.form.reset()
    },
    closeMessageDialog() {
      this.$store.dispatch('dismissError')
    }
  },
  computed: {
    loading() {
      return this.$store.getters.isLogginIn
    },
    error() {
      return this.$store.getters.error
    },
    errorMessage() {
      return this.$store.getters.errorMessage
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../main.scss';


</style>

<style lang="scss">
@import '../main.scss';

.splash-input {
  // color: $font-white!important;
  label {
    // color: $font-white!important;
  }

  &:not(.input-group--error) .input-group__details::before {
    background-color: $input-border-light !important;
  }

  input {
    // color:$green-accent!important;
  }

  .input-group__details {
    min-height: 4px;
  }

}

.splash {
  .ripple__container {
    display: none;
  }

  .submit-btn {
    margin: 16px 0;
    padding: 12px;
    width: 100%;
    height: 100%;
    // background: $green-accent!important;
    color: $font-white;
    border-radius: 5px;

    .btn__content {
      padding: 10px 16px;
    }
  }
}

</style>
 