import * as types from '../mutation-types'
import helper from '../../services/helpers'

const state = {
    // isLoggedIn: !!localStorage.getItem('token')
    breadcrumbs: []
}
// getters
const getters = {
    breadcrumbs: state => {
        return state.breadcrumbs
    }
}
// actions
const actions = {
    updateBreadrumbs({commit, state}, path) {
        var breadcrumbs = []
        var breads = path.split('/')
        var link = '#'
        for (var i = 1, l = breads.length; i < l; i++) {
            link += '/' + breads[i]
            var item = {
                text: breads[i],
                link: link,
                disabled: false
            }
            if (breads[i - 1] === 'Locks') {
                item.text = helper.getLockName(breads[i])
            }
            if (i === l - 1) {
                item.disabled = true
            }
            breadcrumbs.push(item)
        }
        commit(types.UPDATE_BREADCRUMBS, breadcrumbs)
    }
}
// mutations
const mutations = {
    [types.UPDATE_BREADCRUMBS](state, breadcrumbs) {
        state.breadcrumbs = breadcrumbs
    }
}
// export
export default {
    state,
    getters,
    actions,
    mutations
}
