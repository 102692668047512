import dataService from '../../services/dataServices'
import * as types from '../mutation-types'

const state = {
    // isLoggedIn: !!localStorage.getItem('token')
    devices: JSON.parse(localStorage.getItem('devices')),
}
// getters
const getters = {}
// actions
const actions = {
    refreshDevices({_, commit}) {
        dataService.getAllDevices().then(function success(response) {
            localStorage.setItem('devices', JSON.stringify(response.data))
            commit(types.UPDATE_ALL_DEVICES)
        })
    }
}
// mutations
const mutations = {
    [types.UPDATE_ALL_DEVICES](state) {
        state.devices = JSON.parse(localStorage.getItem('devices'))
    },
}
// export
export default {
    state,
    getters,
    actions,
    mutations
}
