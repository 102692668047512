<template>
  <div>
    <v-container container--fluid grid-list-md>
      <v-layout row wrap>
        <v-flex chart d-flex md6 sm12>
          <remove-users></remove-users>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import removeUsers from '../components/RemoveUsers'

export default {
  name: 'settings',
  components: {
    'remove-users': removeUsers
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../main.scss';

.flex {
  display: block;
}
</style>
  