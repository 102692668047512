<template>
  <div class="app-view">
    <d-sidebar></d-sidebar>
    <v-breadcrumbs>
      <v-icon slot="divider">chevron_right</v-icon>
      <v-breadcrumbs-item
          v-for="item in items" :key="item.text"
          :disabled="item.disabled"
          :href="item.link"
      >
        {{ item.text }}
      </v-breadcrumbs-item>
    </v-breadcrumbs>
    <router-view class="dashboard scroll">
    </router-view>
  </div>
</template>

<script>
export default {
  name: 'dashboard',
  data() {
    return {}
  },
  mounted() {
  },
  computed: {
    items() {
      return this.$store.getters.breadcrumbs
    }
  },
  methods: {
    refreshDevices() {
      this.$store.dispatch('refreshDevices')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../main.scss';


.breadcrumbs {
  justify-content: left;
  padding-top: 16px;
}
</style>
<style lang="scss">
.breadcrumbs {
  a {
    font-size: 20px !important;
  }
}

.container.grid-list-md {
  padding: 0;
}
</style>

