<template>
  <div class="bar-chart">
    <h6>{{ label }}</h6>
    <bar-chart :items="items" :label="''" :x="x" :y="y" class="chart"></bar-chart>
  </div>
</template>

<script>
import barchart from './BarChart'

export default {
  props: ['label', 'items', 'x', 'y'],
  name: 'BarChart',
  components: {
    'bar-chart': barchart
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../main.scss';

bar-chart {
  height: 100%;
  width: 100%;
}

.chart {
  display: block;
  position: relative;
  width: 100%;
  height: calc(40vh - 80px);
}

h6 {
  margin-bottom: 24px;
}
</style>
  