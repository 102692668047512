import config from '../config'
import axios from 'axios'

const baseUrl = config.baseUrl();

export default {
    removeUser(userId) {
        return axios.delete(baseUrl + '/su/user/' + userId, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.token
            }
        })
    }
}
