import config from '../config'
import axios from 'axios'
import jwtDecode from "jwt-decode"

var baseUrl = config.baseUrl() + '/auth'

export default {
    login: function (user) {
        return new Promise((resolve, _) => {
            axios
                .post(baseUrl + "/token/", user)
                .then(function (response) {
                    localStorage.token = response.data.authToken;
                    localStorage.refreshToken = response.data.refreshToken;
                    localStorage.privateKey = response.data.privateKey;
                    localStorage.publicKey = response.data.publicKey;
                    localStorage.email = jwtDecode(localStorage.token).email;
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error);
                });
        });
    },
    register: function (user) {
        return axios
            .post(baseUrl + "/register/", user, {
                headers: {
                    Accept: "application/vnd.doordeck.api-v2+json",
                },
            })
            .then(
                function (response) {
                    localStorage.token = response.data.authToken;
                    localStorage.refreshToken = response.data.refreshToken;
                    localStorage.privateKey = response.data.privateKey;
                    localStorage.publicKey = response.data.publicKey;
                    localStorage.email = jwtDecode(localStorage.token).email;
                    return response;
                },
                function error(response) {
                    return response;
                }
            );
    },
    refreshToken: function () {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    baseUrl + "/token/refresh",
                    {},
                    {
                        skipAuthorization: true,
                        headers: {
                            Authorization: "Bearer " + localStorage.refreshToken,
                        },
                    }
                )
                .then(function (response) {
                    localStorage.token = response.data.authToken;
                    if (response.data.refreshToken) {
                        localStorage.refreshToken = response.data.refreshToken;
                    }
                    resolve(response);
                })
                .catch((err) => reject(err));
        });
    },
    logout: function () {
        return axios
            .post(
                baseUrl + "/token/destroy",
                {},
                {headers: {Authorization: "Bearer " + localStorage.token}}
            )
            .then(function () {
                localStorage.clear()
                sessionStorage.clear()
            });
    },
}
