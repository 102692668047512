var currentLang = 'en'

export default {
    // global context
    en: {
        YES: 'Yes',
        NO: 'No',
        CANCEL: 'Cancel',
        OK: 'Ok',
        CONTINUE: 'Continue',
        CLOSE: 'Close',
        BACK: 'Back',
        DONE: 'Done',
        SAVE: 'Save',
        DESELECT: 'Deselect',
        VIEWMORE: 'View more',
        TRY_AGAIN: 'Please try again.',
        REMOVE: 'Remove',
        EMAIL: 'E-mail',
        PASSWORD: 'Password',
        CREATE_PASSWORD: 'Create a password',
        CREATE_ACCOUNT: 'Create account',
        LOGIN: 'Log in',
        REGISTER: 'Create account',
        WELCOME: 'Welcome',
        DEEPLINK_INFO: 'All you need to do is set a password and you are ready to unlock your doors.',
        DEEPLINK_EXPIRED: 'This activation link has expired.',
        DEEPLINK_EXPIRED_SUB: 'Please ask your admin to reshare the lock with you to receive a new link.',
        NO_INTERNET_CONNECTION: 'No internet connection.',
        WRONG_CREDENTIALS: 'Wrong credentials.',
        SOMETHING_WENT_WRONG: 'Something went wrong.',
        CREATE_NEW_ACCOUNT: 'Create a new account.',
        ALREADY_ACCOUNT: 'Already have an account.',
        CHANGE_PASSWORD: 'Change password',
        CHANGE_PASSWORD_SUCCESS: 'Password successfully updated.',
        CHANGE_DISPLAY_NAME: 'Change display name',
        CHANGE_DISPLAY_SUCCESS: 'Display name successfully updated.',
        DISPLAY_NAME: 'Display name',
        REP_PASSWORD: 'Repeat password',
        NEW_PASSWORD: 'New password',
        OLD_PASSWORD: 'Old password',
        PASSWORD_DONT_MATCH: 'Passwords are not matching.',
        REQUIRED: 'Required.',
        MIN_CHAR_PASS: 'Needs to be at least 5 characters.',
        INVALID_EMAIL: 'Invalid email address.',
        USERS: 'Users',
        ADMINS: 'Admins',
        UNLOCK: 'Unlock',
        UNLOCKED: 'Unlocked',
        ACTIVITY: 'Activity',
        DOOR_LOCKED: 'Door locked',
        DOOR_UNLOCKED: 'Door unlocked',
        UNLOCK_REQUEST: 'Unlock requested',
        LOCK_REQUEST: 'Lock requested',
        LOCK_SHARED: 'Lock shared with',
        USER_PROMOTED: 'made admin',
        USER_REVOKED: 'was removed',
        NO_LOCKS: 'You seem to have no Keys yet.',
        NO_USERS: 'You have no admin rights on any of your Keys.',
        BY: 'By',
        DEVICE_CONNECTED: 'Device connected',
        DEVICE_DISCONNECTED: 'Device disconnected',
        GENERAL_ERROR: 'Something went wrong',
        SELECT_MIN_DAY: 'Select minimum 1 day.',
        SELECT_MIN_LOCK: 'Select minimum 1 lock.',
        SELECT_MIN_EMAIL: 'Select minimum 1 email.',
        TIME_RESTRICTIONS: 'Time restrictions',
        LOCATION_RESTRICTIONS: 'Location restrictions',
        NO_TIME_RESTRICTIONS: 'No Time restrictions applied.',
        NO_TIME_RESTRICTIONS_SUB: 'Press the + sign below to add a Time restriction.',
        ADD_RESTRICTION_SUCCESS: 'Successfully added Time Restriction.',
        REMOVE_RESTRICTION_SUCCESS: 'Successfully removed Time Restriction.',
        CONFIRM_REMOVE_TIMERESTRICTION: 'Are you sure you want to remove this Time Restriction?',
        MAKE_USER_SUCCESS: 'has been successfully made a user.',
        MAKE_ADMIN_SUCCESS: 'has been successfully made a admin.',
        OPEN_HOURS: 'Open Hours',
        NO_OPEN_HOURS: 'No Open Hours applied.',
        NO_OPEN_HOURS_SUB: 'Press the + sign below to add Open Hours.',
        ADD_OPENHOURS_SUCCESS: 'Successfully added Open Hours.',
        REMOVE_OPENHOURS_SUCCESS: 'Successfully removed Open Hours.',
        CONFIRM_REMOVE_OPENHOURS: 'Are you sure you want to remove Open Hours?',
        IP_RESTRICTIONS: 'Ip Restrictions',
        NO_IP_RESTRICTIONS: 'No IP restrictions applied.',
        NO_IP_RESTRICTIONS_SUB: 'Press the + sign below to add a IP restriction.',
        CONFIRM_REMOVE_IPRESTRICTION: 'Are you sure you want to remove this Time Restriction',
        ADD_IP_SUCCESS: 'Successfully added IP Restriction.',
        REMOVE_IP_SUCCESS: 'Successfully removed IP Restriction.',
        IP_ENTER: 'After typing the IP address press enter to add it.',
        SETTINGS: 'Settings',
        CHECKSAVE: 'Would you like to save your changes?',
        DEVICES: 'Devices',
        SELECT_LOCKS: 'Select Keys',
        SELECT_USERS: 'Select users',
        OPTIONS: 'Options',
        SHARE: 'Share',
        SHARING_LOCKS: 'Sharing Keys',
        ALL_LOCKS_SHARED: 'All Keys were shared successfully.',
        NOT_ALL_LOCKS_SHARED: 'Not all Keys were shared successfully.',
        REMOVE_USERS_CONFIRM: 'Are you sure you want to remove the following users?',
        REMOVING_USERS: 'Removing_users',
        ALL_USERS_REMOVED: 'All users removed succesfully.',
        REMOVING_DEVICE: 'Removing Device',
        DEVICE_REMOVED_SUCCESSFULLY: 'Device successfully removed.',
        CONFIRM_REMOVE_DEVICE_USER: 'Are you sure you want to remove this device from user ',
        CONFIRM_REMOVE_USER_DEVICE: 'Are you sure you want to remove this user from ',
        CONFIRM_REMOVE_USERS_DEVICE: 'Are you sure you want to remove these users from ',
        NO_ACTIVITY: 'There seem to be no activity.',
        WOOPS: 'Wooops!',
        UPDATED_DEVICE_SETTINGS_SUCCESS: 'Device settings updated.',
        GEOFENCING: 'Geofencing',
        NO_GEOFENCING_RESTRICTIONS: 'No Geofencing set up.',
        NO_GEOFENCING_SUB: 'Press the + sign below to set up.',
        ACCURRACY_FINE: 'FINE: < 10m (slowest loading)',
        ACCURRACY_HIGH: 'HIGH: < 20m (medium loading)',
        ACCURRACY_MEDIUM: 'MEDIUM: < 100m (faster loading)',
        ACCURRACY_LOW: 'LOW: > 500m (fastest loading)',
        SET_LOCATION: 'Set location',
        SET_RADIUS: 'Set radius',
        SELECT_ACCURACY: 'Select accuracy',
        GEO_NOT_ENABLED_TITLE: 'Geofencing is not enabled.',
        GEO_NOT_ENABLED_SUB: 'Press the switch in top right to enable.',
        SEARCH_USERS_ADMINS: 'Search users and admins',
        SEARCH_ACTIVITY: 'Search activity',
        LOAD_MORE: 'Load more',
        SUBTILE_LINK_STEP1: 'Let’s get started linking the Tile to the lock.',
        TILE_LINK_STEP1: 'Scan the QR on the Tile.',
        SUBTILE_LINK_STEP2: 'The Tile looks valid, now just select the lock you want to associate with.',
        TILE_LINK_STEP2: 'Select the lock.',
        NO_TILE: 'No Tiles',
        ONE_OR_MORE_TILES: 'One or more Tiles',
        NO_LOCK_SELECTED: 'No lock selected',
        PLEASE_SELECT_LOCK: 'Please select a lock.',
        TILE_LINK_SUCCESS: 'The Tile is now linked.',
        SUCCESS: 'Success!',
        RETRY: 'Try again',
        SCAN_NEW_TILE: 'Scan new Tile',
        TILE_ALREADY_LINKED: 'Tile is already linked.',
        DEFAULT_NAME: 'Set as default name for everyone.',
        SHARE_EMAILS: 'Email addresses (you can past in as many as you want)',
        EMAIL_INVALID_MSG: 'This email address does not exist.',
        FREE_SITE_CATEGORY: 'FREE',
        CUSTOMER_SITE_CATEGORY: 'CUSTOMER',
        TRIAL_SITE_CATEGORY: 'TRIAL',
        UNCATEGORISED_SITE_CATEGORY: 'UNCATEGORISED',
        LOGOUT: 'Logout',

    },
    getString(string) {
        return this[currentLang][string]
    },
    getCurrentLang() {
        return currentLang
    },
    setCurrentLang(lang) {
        currentLang = lang
    }
}
