import Vue from 'vue'
import VueRouter from 'vue-router'

import dashboard from '@/views/Dashboard'

import sites from '@/views/Sites'
import sitesDetail from '@/views/SitesDetail'
import settings from '@/views/Settings'
import tiles from '@/views/Tiles'

import splash from '@/views/Splash'
import login from '@/views/Login'
import register from '@/views/Register'
import store from "@/store";

Vue.use(VueRouter)

const routes = [
    {
        path: '/splash',
        name: 'Splash',
        component: splash,
        children: [
            {
                // UserProfile will be rendered inside User's <router-view>
                // when /user/:id/profile is matched
                name: 'login',
                path: '/login',
                component: login
            },
            {
                // UserPosts will be rendered inside User's <router-view>
                // when /user/:id/posts is matched
                name: 'register',
                path: '/register',
                component: register
            }
        ]
    },
    {
        path: '/',
        name: 'dashboard',
        component: dashboard,
        redirect: {name: 'sites'},
        meta: {auth: true},
        children: [
            {
                name: 'sites',
                path: '/Sites',
                component: sites
            },
            {
                name: 'site-detail',
                path: '/Sites/:id',
                component: sitesDetail
            },
            {
                name: 'settings',
                path: '/Settings',
                component: settings
            },
            {
                name: 'tiles',
                path: '/Tiles',
                component: tiles
            }
        ]
    },
    {
        path: '*',
        redirect: {name: 'dashboard'}
    }
]
const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(route => route.meta.auth)) {
        store.dispatch('checkToken').then(check => {
            if (check) {
                next()
            } else {
                next('/login')
            }
        })
    } else {
        next()
    }
})

router.afterEach((to, _, __) => {
    store.dispatch('updateBreadrumbs', to.path)
})
export default router
