<template>
  <div>
    <v-dialog id="idSitedDialog" v-model="addSiteDialog"
              content-class="white"
              fullscreen
              persistent
              scrollable
              transition="dialog-bottom-transition"
    >
      <v-card class="elevation-0">
        <v-container>
          <v-card-text>
            <v-card-title class="headline">Add a Site</v-card-title>
            <div class="card-section">
              <v-container container--fluid>
                <v-layout row wrap>
                  <v-flex>
                    <v-text-field
                        id="site-name"
                        v-model="siteName"
                        label="Site name"
                        name="siteName"
                    ></v-text-field>
                    <v-text-field
                        id="site-credits"
                        v-model="siteCredits"
                        label="Door credits"
                        name="siteName"
                        type="number"
                    ></v-text-field>
                    <v-select
                        v-model="siteCategory"
                        :items="arrays.SiteCategories"
                        item-text="name"
                        item-value="name"
                        label="Site Category"
                        single-line
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-container>
              <h5 class="title">Site admin(s) </h5>
              <v-container>
                <v-combobox
                    v-model="adminSelect"
                    :items="Array.from(adminMap).map((e) => { return e[0]; })"
                    :label="strings.getString('SHARE_EMAILS')"
                    chips
                    clearable
                    multiple
                    solo
                    tags
                    v-on:blur="updateAdmins()"
                    v-on:keyup.enter="updateAdmins()"
                    v-on:keyup.tab="updateAdmins()"
                >
                  <template slot="selection" slot-scope="data">
                    <v-chip :input_value="data.selected" close>
                      <strong>{{ data.item }}</strong>
                    </v-chip>
                  </template>
                </v-combobox>
              </v-container>
              <h5 class="title"> Location </h5>
              <v-container container--fluid>
                <v-list>
                  <v-list-item @click="openEditLocation()">
                    <v-list-item-action>
                      <div class="dialog-action-button">
                        <v-icon>fa-map-marker</v-icon>
                      </div>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ getAddress.street_number }} {{ getAddress.street }}</v-list-item-title>
                      <v-list-item-subtitle>{{ getAddress.postal_code }} {{ getAddress.city }}
                        {{ getAddress.country }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <!-- <span>{{latlng}}</span> -->
              </v-container>
              <h5 class="title"> Customization </h5>
              <v-container container--fluid>
                <v-layout row wrap>
                  <v-flex sm1 xs1>
                    <div class="color-pick" v-bind:style="{ 'background': color }"></div>
                  </v-flex>
                  <v-flex sm5 xs11>
                    <v-select
                        v-model="color"
                        :items="colors"
                        item-text="name"
                        item-value="name"
                        label="Select Color"
                        single-line
                    >
                      <template slot="item" slot-scope="data">
                        <template>
                          <div class="color-pick" v-bind:style="{ 'background': data.item }"></div>
                          <v-list-item-content>
                            <v-list-item-title v-html="data.item"></v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-select>
                  </v-flex>
                  <v-flex sm5 xs11>

                  </v-flex>
                </v-layout>
              </v-container>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click.native="dismissRestrictionDialog">Cancel</v-btn>
            <v-btn color="cyan" @click.native="addSite">Save</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="locationDialog" persistent>
      <v-card>
        <v-card-title class="headline">Edit location and radius</v-card-title>
        <v-card-text>
          <LocationDialog :reloadMap="locationDialog"></LocationDialog>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="cyan" @click.native="dismissLocationDialog">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="messageDialog" class="d-dialog" max-width="500" width="400">
      <v-card>
        <v-card-title class="headline">{{ title }}</v-card-title>
        <v-card-text>{{ subTitle }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="cyan" @click.native="closeMessageDialog()">Done</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LocationDialog from './LocationDialog'
import globalColors from '../services/colors'
import strings from '../services/strings'
import arrays from "@/services/arrays";

export default {
  name: 'AddSiteDialog',
  data() {
    return {
      siteName: '',
      showMap: false,
      locationDialog: false,
      messageDialog: false,
      siteCredits: '',
      colors: globalColors.getColor('SITE_COLORS'),
      color: '#57355D',
      adminMap: new Map(),
      adminSelect: [],
      title: '',
      subTitle: '',
      siteCategory: ''
    }
  },
  methods: {
    updateDialog() {
      this.addSiteDialog = this.show
    },
    openEditLocation() {
      this.locationDialog = true
    },
    dismissLocationDialog() {
      this.locationDialog = false
    },
    dismissRestrictionDialog() {
      this.$store.dispatch('openAddSiteDialog', false)
    },
    addSite() {
      const site = {
        name: this.siteName,
        administrators: this.getAdminIds(),
        creditRemaining: parseInt(this.siteCredits),
        latitude: this.location.latitude,
        longitude: this.location.longitude,
        radius: this.location.radius,
        colour: this.color,
        category: this.siteCategory
      };
      this.$store.dispatch('addSite', site).then(response => {
        this.$store.dispatch('openAddSiteDialog', false)
        this.$store.dispatch('refreshSites')
      })
    },
    getAdminIds() {
      let adminIds = []
      for (let i = 0, l = this.adminSelect.length; i < l; i++) {
        adminIds.push(this.adminMap.get(this.adminSelect[i]))
      }
      return adminIds
    },
    async updateAdmins() {
      if (!this.adminSelect.length > 0) return false

      let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      let lastItem = this.adminSelect.pop()
      let split = lastItem.split(/(?:,| |;)+/)
      let invalidUsers = []

      for (const element of split) {
        if (emailRegex.test(String(element).toLowerCase())) {
          try {
            let userId = "";

            if (this.adminMap.has(element)) {
              userId = this.adminMap.get(element)
            } else {
              const response = await this.$store.dispatch('getUser', element)
              userId = response.data.id
            }

            this.adminSelect.push(element)
            this.adminMap.set(element, userId)
          } catch (error) {
            invalidUsers.push(element)
          }
        }
      }

      if (invalidUsers.length > 0) {
        this.openMessageDialog('Invalid users', "The following users don't exist: " + invalidUsers.toString())
      }
    },
    openMessageDialog(title, subtitle) {
      this.title = title
      this.subTitle = subtitle
      this.messageDialog = true
    },
    closeMessageDialog() {
      this.messageDialog = false
    }
  },
  computed: {
    strings() {
      return strings
    },
    arrays() {
      return arrays
    },
    getAddress() {
      return this.$store.getters.getAddressSiteToAdd
    },
    addSiteDialog() {
      return this.$store.getters.getAddSiteDialog
    },
    location() {
      return this.$store.getters.getCoordSiteToAdd
    }
  },
  components: {
    LocationDialog
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../main.scss';

.autocomplete {
  width: 100%;
  border-bottom: 1px solid rgba(black, 0.4);
  padding: 4px 0;
  font-size: 16px;
}

.container--fluid {
  padding: 16px;
}

.title {
  padding: 16px;
}

.color-pick {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 16px 32px;
}
</style>