// import * as types from '../mutation-types'

// state
const state = {
    addressSite: {
        premise: 'IdeaLondon',
        street_number: '69',
        street: 'Wilson Street',
        city: 'London',
        country: 'GB',
        postal_code: 'EC2A 2BB'
    },
    coordSite: {
        latitude: 51.522006,
        longitude: -0.084733,
        radius: 50
    }
    // isLoggedIn: true
}
// getters
const getters = {
    getAddressSiteToAdd: state => {
        return state.addressSite
    },
    getCoordSiteToAdd: state => {
        return state.coordSite
    }
}
// actions
const actions = {
    updateAddressToAdd({commit}, address) {
        // commit(types.LOGIN_REQUEST) // show spinner
        // commit(types.SITES_REFRESHING)
        state.addressSite = address
    },
    updateSiteCoordToAdd({commit}, coord) {
        // commit(types.LOGIN_REQUEST) // show spinner
        // commit(types.SITES_REFRESHING)
        state.coordSite = coord
    }
}
// mutations
const mutations = {}

// export
export default {
    state,
    getters,
    actions,
    mutations
}
