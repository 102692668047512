<template>
  <div>
    <v-container container--fluid grid-list-md>
      <v-flex chart class="search-container" d-flex md12 sm12 wrap>
        <v-text-field v-model="search" append-icon="fa fa-search" label="Search site"></v-text-field>
        <v-btn class="refreshlocks-btn secondary" text @click="refreshLocks">
          Refresh locks
        </v-btn>
        <download-excel :data="getSites"
                        :name="'sites.csv'"
                        :title="getSiteName"
                        :type="'csv'"
                        class="export-btn secondary">
          Export sites
        </download-excel>
      </v-flex>
      <v-layout row wrap>
        <v-flex chart d-flex md12 sm12>
          <v-data-table
              :headers="list.sites.headers"
              :items="getSites"
              :items-per-page="10"
              :loading="loading"
              class="elevation-1">

            <template v-slot:item.name="{ item }">
              <a @click="goToLink(item.id, 'site-detail')">
                {{ item.name }}
              </a>
            </template>

            <template v-slot:item.created="{ item }">
              {{ getDate(item.created) }}
            </template>

          </v-data-table>
        </v-flex>
      </v-layout>
      <v-btn class="addBtn" color="cyan" @click="addSite">Add Site</v-btn>
      <v-layout row wrap>
        <v-flex chart d-flex sm12>
        </v-flex>
      </v-layout>
      <v-layout>
        <add-site-dialog></add-site-dialog>
      </v-layout>
    </v-container>

  </div>
</template>

<script>
import addSiteDialog from '../components/AddSiteDialog'
import moment from 'moment'

export default {
  name: 'Sites',
  data() {
    return {
      list: {
        sites: {
          select: {
            default: 'created'
          },
          headers: [
            {
              text: 'Name',
              sortable: true,
              align: 'left',
              value: 'name',
              link: 'site-detail',
              link_item: 'id',
              type: 'text'
            },
            {text: 'Site Id', sortable: false, align: 'right', value: 'id', type: 'text'},
            {text: 'Category', sortable: true, align: 'right', value: 'category', type: 'text'},
            {text: 'Created by', sortable: false, align: 'right', value: 'createdBy', type: 'text'},
            {text: 'Created on', sortable: true, align: 'right', value: 'created', type: 'date'}
          ],
          pagination: {
            descending: true,
            row: 20
          }
        }
      },
      data: [
        {name: 'RainMaking Loft', id: '3213213213321'},
        {name: 'IdeaLondon', id: '213213209873'},
        {name: 'HQ Stanley', id: '79813213512551'}
      ],
      search: ''
    }
  },
  methods: {
    addSite() {
      this.$store.dispatch('openAddSiteDialog', true)
    },
    refreshSites() {
      this.$store.dispatch('refreshSites')
    },
    refreshLocks() {
      this.$store.dispatch('getAllLocksFromSites', 0)
    },
    goToLink(item, link) {
      if (link !== undefined) {
        // named route
        this.$router.push({name: link, params: {id: item}})
      }
    },
    getDate(item) {
      return moment.unix(item).format('DD / MM / YYYY')
    },
    getValue(item, header) {
      if (header.type === 'date') {
        return moment.unix(item[header?.value]).format('DD / MM / YYYY')
      } else return item[header?.value]
    }
  },
  computed: {
    getSites() {
      return this.$store.getters.getSites.filter(site => {
        if (site.name.toLowerCase().includes(this.search.toLowerCase())) {
          return site
        }
      })
    },
    getSiteName() {
      return "Doordeck"
    },
    loading() {
      return this.$store.getters.getLoadingSites
    }
  },
  components: {
    addSiteDialog
  },
  mounted() {
    this.refreshSites()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../main.scss';

.link {
  cursor: pointer;
  color: $font-accent;

  &:hover {
    text-decoration: underline;
  }
}

.no-link {
  color: $font-black;
}

.v-select {
  width: 100px;
  margin-top: 4px;
}

.flex {
  display: block;
}

.search-container {
  margin: 0 32px;
}

.export-btn {
  padding: 16px;
  width: 120px;
  max-width: 120px;
  color: white;
  border-radius: 6px;
  height: 50px;
  text-align: center;
  margin: 0 16px;
  font-weight: 500;
  cursor: pointer;
}

.refreshlocks-btn {
  padding: 16px;
  color: white;
  border-radius: 6px;
  height: 50px !important;
  text-align: center;
  margin: 0 16px;
  font-weight: 500;
  cursor: pointer;
}

.elevation-1 {
  width: 100% !important;
}

.addBtn {
  margin-left: 30px;
}
</style>
  