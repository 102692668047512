<template>
  <div>
    <h6 class='d-flex'>Generate Tiles</h6>
    <br/>
    <br/>
    <br/>
    <v-btn color="cyan" @click="generateSheet">Generate ID's</v-btn>
    <v-combobox v-model="ids" append-icon="" chips clearable label="Paste the id's here and press ENTER" multiple
                prepend-icon=""
                solo="" tags v-on:blur="test()" v-on:keyup.enter="test()" v-on:keyup.tab="test()">
      <template slot="selection" slot-scope="data">
        <v-chip :input_value="data.selected" close @input="remove(data.item)">
          <strong>{{ data.item }}</strong>
        </v-chip>
      </template>
    </v-combobox>
    <v-radio-group v-model="type" row>
      <v-radio :value="0" label="V1 Tiles"></v-radio>
      <v-radio :value="1" label="V2 Full Tiles"></v-radio>
      <v-radio :value="2" label="V2 Compact Tiles"></v-radio>
    </v-radio-group>
    <TileV1 v-if='(type === 0)' :ids='ids'></TileV1>
    <TileV2 v-if='(type === 1)' :ids='ids'></TileV2>
    <TileCompact v-if='(type === 2)' :ids='ids'></TileCompact>
  </div>
</template>

<script>

import TileV1 from './TileV1.vue'
import TileV2 from './TileV2.vue'
import TileCompact from './TileCompact.vue'
import helpers from '../../services/helpers'

export default {
  data() {
    return {
      type: 0,
      ids: []
    }
  },
  methods: {
    generateSheet() {
      this.ids = []
      var maxTile = 55
      if (this.type !== 2) {
        maxTile = 27
      }
      for (let i = 0; i < maxTile; i++) {
        this.ids.push(this.generateUUID())
      }
    },
    generateUUID() { // Public Domain/MIT
      var d = new Date().getTime()
      if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now() // use high-precision timer if available
      }
      return helpers.uuidStyle.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0
        d = Math.floor(d / 16)
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
      })
    },
    remove(item) {
      this.ids.splice(this.ids.indexOf(item), 1)
      this.ids = [...this.ids]
    },
    test() {
      var vm = this
      setTimeout(function () {
        var newIds = []
        for (let i = 0, l = vm.ids.length; i < l; i++) {
          var current = vm.ids[i].split(/(?:,| |;)+/)
          for (let j = 0, k = current.length; j < k; j++) {
            newIds.push(current[j])
          }
        }
        vm.ids = newIds
      }, 200)
    }
  },
  components: {
    TileV1,
    TileV2,
    TileCompact
  }
}
</script>